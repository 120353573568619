import React, { useState, useEffect } from "react";
import {globalLoderAction } from "../Redux/Action";
import { useDispatch } from "react-redux";

function RefundandReturn() {
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
     
      <div class="inner-banner inner-banner-bg8">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Refund Policy</h3>
            <ul>
              <li>
                <a style={{ color: "#f9c113" }} href="/">
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>Refund Policy</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="privacy-policy-area pt-100 pb-70">
        <div class="container">
          <div class="section-title text-center">
            <div class="section-subtitle">
              <img src="assets/images/section-icon.png" alt="Section Images" />
              <span>Return & Refund Policy</span>
            </div>
            <h2>Aadhi Marudham Return & Refund Policy</h2>
          </div>
          <div class="row pt-45">
            <div class="col-lg-12">
              <div class="single-content">
                <h3>Definitions and key terms</h3>
                <h6>
                  To help explain things as clearly as possible in this Return &
                  Refund Policy, every time any of these terms are referenced,
                  are strictly defined as:
                </h6>
                <ul>
                  <li>
                    <h6>Company :</h6>
                    <span>
                      When this policy mentions "Company," "we," "us," or "our,"
                      it refers to Aadhi Marudham, 115/3, R,S Road, Near Royal
                      Theatre, Pallipalayam, Erode - 638 006, Namakkal District,
                      that is responsible for your information under this Return
                      & Refund Policy.
                    </span>
                  </li>
                  <li>
                    {" "}
                    <h6>Customer :</h6>
                    <span>
                      Refers to the company, organization or person that signs
                      up to use the Service to manage the relationships with
                      your consumers or service users.
                    </span>
                  </li>
                  <li>
                    {" "}
                    <h6>Device :</h6>
                    <span>
                      Any internet connected device such as a phone, tablet,
                      computer or any other device that can be used to visit and
                      use the services.
                    </span>
                  </li>
                  <li>
                    {" "}
                    <h6>Service :</h6>
                    <span>
                      Refers to the service provided by aadhi marudham as
                      described in the relative terms (if available) and on this
                      platform.
                    </span>
                  </li>
                  <li>
                    {" "}
                    <h6>Website :</h6>
                    <span>
                      Aadhi Marudham site, which can be accessed via this URL:
                      https://aadhimarudham.in/.
                    </span>
                  </li>
                  <li>
                    {" "}
                    <h6>You :</h6>
                    <span>
                      A person or entity that is registered with Aadhi Marudham
                      to use the Services.
                    </span>
                  </li>
                </ul>
              </div>
              <div class="single-content">
                <h3>Shipping</h3>
                <p>
                  Aadhi Marudham is responsible for return shipping costs. Every
                  returning shipping is paid by aadhimarudham, even if the item
                  didn't have free shipping in the first place.
                </p>
              </div>
              <div class="single-content">
                <h3>Return & Refund Policy</h3>
                <p>
                  Thanks for shopping at aadhi marudham. We appreciate the fact
                  that you like to buy the stuff we build. We also want to make
                  sure you have a rewarding experience while you're exploring,
                  evaluating, and purchasing our products.
                </p>
                <p>
                  As with any shopping experience, there are terms and
                  conditions that apply to transactions at aadhimarudham. We'll
                  be as brief as our attorneys will allow. The main thing to
                  remember is that by placing an order or making a purchase at
                  aadhimarudham, you agree to the terms set forth below along
                  with aadhimarudham's Privacy Policy.
                </p>
                <p>
                  If there's something wrong with the product you bought, or if
                  you are not happy with it, you will not be able to issue a
                  refund for your Item.
                </p>
              </div>
              <div class="single-content">
                <h3> Refunds</h3>

                <p>
                  We at aadhimarudham commit ourselves to serving our customers
                  with the best products. Every single product that you choose
                  is thoroughly inspected, checked for defects and packaged with
                  utmost care. We do this to ensure that you fall in love with
                  our products.
                </p>
                <p>
                  Sadly, there are times when we may not have the product(s)
                  that you choose in stock, or may face some issues with our
                  inventory and quality check. In such cases, we may have to
                  cancel your order. You will be intimated about it in advance
                  so that you don't have to worry unnecessarily about your
                  order. If you have purchased via Online payment (not Cash on
                  Delivery), then you will be refunded once our team confirms
                  your request.
                </p>
                <p>
                  We carry out thorough quality check before processing the
                  ordered item. We take utmost care while packing the product.
                  At the sametime we ensure that the packing is good such that
                  the items won't get damaged during transit. Please note that
                  aadhimarudham is not liablefor damages that are caused to the
                  items during transit or transportation.
                </p>
              </div>
              <div class="single-content">
                <h3>
                  We follow certain policies to ensure transparency, efficiency
                  and quality customer care:
                </h3>
                <ul>
                  <li>
                    We DO NOT allow returns on sold products - online or in
                    retail outlets.
                  </li>
                  <li>
                    We DO NOT accept returned goods, as we believe that
                    customers should get the best quality products.
                  </li>
                  <li>
                    Refunds are NOT given for any purchases made - be they
                    online or in retail store.
                  </li>
                  <li>We DO NOT encourage exchanges of our products.</li>
                  <li>
                    We DO NOT engage in reselling used products and discourage
                    the same, because we cannot ensure the best quality products
                    for our customers.
                  </li>
                </ul>
              </div>
              <div class="single-content">
                <h3> For International Orders:</h3>
                <ul>
                  <li>We DO NOT support Exchanges or Returns.</li>
                  <li>
                    If you cancel the order before we process it and dispatch
                    for shipping, a refund can be processed. Orders generally
                    take 1-2 days to process before dispatch.
                  </li>
                  <li>
                    Orders already in shipping cannot be returned, canceled or
                    refunded.
                  </li>
                  <li>
                    If you face any issues, please contact our Support Team
                    immediately.
                  </li>
                </ul>
              </div>
              <div class="single-content">
                <h3> Your Consent</h3>
                <p>
                  By using our platform, registering an account, or making a
                  purchase, you hereby consent to our Return & Refund Policy and
                  agree to its terms.
                </p>
              </div>{" "}
              <div class="single-content">
                <h3> Changes To Our Return & Refund Policy</h3>
                <p>
                  Should we update, amend or make any changes to this document
                  so that they accurately reflect our Service and policies.
                  Unless otherwise required by law, those changes will be
                  prominently posted here. Then, if you continue to use the
                  Service, you will be bound by the updated Return & Refund
                  Policy. If you do not want to agree to this or any updated
                  Return & Refund Policy, you can delete your account
                </p>
              </div>
              <div class="single-content">
                <h3> Contact Us</h3>
                <p>
                  If, for any reason, You are not completely satisfied with any
                  good or service that we provide, don't hesitate to contact us
                  and we will discuss any of the issues you are going through
                  with our product.
                </p>
              </div>
              <div class="single-content">
                <h6>
                  {" "}
                  <ul>
                    <li>Via Email: sales@aadhimarudham.in</li>
                    <li> Via Phone Number: 7397425248</li>
                  </ul>
                </h6>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RefundandReturn;
