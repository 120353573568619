import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { globalLoderAction } from "../../Redux/Action";
import blog1 from "../../assets/image/blog1.jpg";
import blog2 from "../../assets/image/blog2.jpg";
import blog3 from "../../assets/image/blog4.jpg";

function Blogs() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="inner-banner inner-banner-bg10">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Blogs</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>Blogs</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="blog-area pt-100 pb-70">
        <div class="container">
          <div class="section-title text-center mb-45">
            <div class="section-subtitle">
              <img src="assets/images/section-icon.png" alt="Section Images" />
              <span>Latest News</span>
            </div>
            <h2>Definitive Honey Collection</h2>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="blog-item">
                <a href="/blogs_one">
                  <img src={blog2} alt="Blog" />
                </a>
                <div class="content">
                  <h4 style={{ color: "black" }}>
                    The Benefits of Organic Honey: Why Aadhi Marudham Stands Out
                  </h4>

                  <p>
                    Organic honey has become quite popular lately, and it&#39;s
                    no wonder why. However, not all honey is the same, and many
                    people are turning to pure, organic options like Aadhi
                    Marudham.
                  </p>
                  <a href="/blogs_one" class="read-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item">
                <a href="/blogs_two">
                  <img src={blog1} alt="Blog" />
                </a>
                <div class="content">
                  <h4>
                    Honey and Your Health: Debunking Myths and Exploring Facts
                  </h4>

                  <p>
                    Honey, often called &quot;liquid gold,&quot; has been a part
                    of human diets and traditional medicine for a long time.
                    Besides being delicious, honey offers various potential
                    health advantages.
                  </p>
                  <a href="/blogs_two" class="read-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item">
                <a href="/blogs_three">
                  <img src={blog3} alt="Blog" />
                </a>
                <div class="content">
                  <h4>
                    Exploring the Flavors of Aadhi Marudham: A Honey Tasting
                    Guide
                  </h4>

                  <p>
                    Honey is a delightful gift from nature, renowned for its
                    sweetness, diverse flavours, and potential health benefits.
                    We specialize in crafting high-quality honey sourced from
                    unique floral origins.
                  </p>
                  <a href="/blogs_three" class="read-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Blogs;
