import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, Button } from "antd";
import { getordersdetails, globalLoderAction } from "../Redux/Action";
import TrackingOrder from "./TrackingOrder";
import ReviewContainer from "./ReviewContainer";

function Getorderdetails() {
  const [show, setShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null); // Track the index of the selected product
  const [load, setLoad] = useState(true);
  const { id } = useParams();
  const [getid, setGetid] = useState({ orderId: id });
  const dispatch = useDispatch();

  const getdetails = useSelector((state) => state.getordersdetails.payload);
  const deliverinfo = getdetails?.orderInfo?.orderStatus;

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    dispatch(getordersdetails({ orderId: id }))
      .then(() => setShow(false))
      .catch(() => console.log("Waiting For Network"))
      .finally(() => dispatch(globalLoderAction("hideLoader")));
  }, [dispatch, id]);

  const handleReviewClick = (product, index) => {
    setSelectedProduct(product);
    setSelectedIndex(index); // Set the index of the selected product
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setSelectedProduct(null);
    setSelectedIndex(null); // Clear the selected index
  };

  const refreshOrderDetails = () => {
    setLoad(true);
    dispatch(getordersdetails(getid)).finally(() => setLoad(false));
  };

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    refreshOrderDetails();
  }, [dispatch, getid]);

  return (
    <div>
      <div className="container-fluid">
        <TrackingOrder />
      </div>
      <section className="cart-wraps-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form>
                {Array.isArray(getdetails?.productInfo) &&
                  getdetails?.productInfo.length !== 0 && (
                    <h3 style={{ display: "flex", justifyContent: "center" }}>
                      Product Review
                    </h3>
                  )}
                <div className="cart-wraps">
                  <div className="cart-table table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        {Array.isArray(getdetails?.productInfo) &&
                          getdetails?.productInfo.length !== 0 && (
                            <tr>
                              <th scope="col">Product</th>
                              <th scope="col">Name</th>
                              <th scope="col">Unit</th>
                              <th scope="col">Total</th>
                              {deliverinfo === "delivered" && (
                                <th scope="col">Review</th>
                              )}
                            </tr>
                          )}
                      </thead>
                      <tbody>
                        {Array.isArray(getdetails?.productInfo) &&
                        getdetails?.productInfo.length !== 0 ? (
                          getdetails.productInfo.map((product, i) => (
                            <tr key={i}>
                              <td className="product-thumbnail">
                                <img
                                  src={`${process.env.REACT_APP_URL}${product.variantImage}`}
                                  height={80}
                                  width={80}
                                  alt="item"
                                  style={{ objectFit: "cover" }}
                                />
                              </td>
                              <td
                                className="product-name"
                                style={{ textTransform: "capitalize" }}
                              >
                                {product.productName}
                              </td>
                              <td className="product-price">
                                <span className="unit-amount">
                                  {product.units}
                                </span>
                              </td>
                              <td className="product-name">
                                ₹{product.totalPrice}
                              </td>
                              {deliverinfo === "delivered" && (
                                <td className="product-price">
                                  <span className="unit-amount">
                                    {product.isReviewed === 1 ? (
                                      <Button
                                        onClick={() =>
                                          handleReviewClick(product, i)
                                        } // Pass product and index
                                      >
                                        Write Review
                                      </Button>
                                    ) : (
                                      <Button disabled>Reviewed</Button>
                                    )}
                                  </span>
                                </td>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} style={{ textAlign: "center" }}>
                              <h3 className="mb-5 pb-3">No Order Found</h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title="Product Review"
        visible={show}
        onCancel={closeModal}
        footer={null}
      >
        {selectedProduct && (
          <ReviewContainer
            order={selectedProduct}
            index={selectedIndex} // Pass the index as a prop to ReviewContainer
            onClose={() => {
              setShow(false); // Close the modal
              refreshOrderDetails(); // Reload the data
            }}
          />
        )}
      </Modal>
    </div>
  );
}

export default Getorderdetails;
