import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { googlelogin } from "../Redux/Action";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { message } from "antd";

const WebViewRedirect = () => {
  const [redirectTriggered, setRedirectTriggered] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSocialMediaWebView()) {
      redirectToSystemBrowser();
    }
  }, []);

  const isSocialMediaWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /Instagram|FBAN|FBAV|Facebook|Snapchat|Twitter/i.test(userAgent); // Detect common social media WebViews
  };

  const redirectToSystemBrowser = () => {
    const url = window.location.href;

    if (/android/i.test(navigator.userAgent)) {
      // Android: Use intent to open in Chrome
      window.location.href = `intent://${url.replace(
        /^https?:\/\//,
        ""
      )}#Intent;scheme=https;package=com.android.chrome;end;`;
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      // iOS: Suggest users open in Safari
      alert("Please open this link in Safari or Chrome for better experience.");
      setRedirectTriggered(true);
    }
  };

  const responseMessage = (response) => {
    const accessToken = response.credential;
    dispatch(googlelogin({ accessToken: accessToken })).then(() => {
      window.location.reload();
    });
  };

  const errorMessage = (error) => {
    console.log(error);
    message.error({
      content: error,
      style: {
        marginTop: "10vh",
      },
    });
  };

  return (
    <GoogleOAuthProvider clientId="664668234502-fm8hm218efco9iak3cvaulug6e06d400.apps.googleusercontent.com">
      <div>
        {redirectTriggered ? (
          <p>Please open this link in your default browser.</p>
        ) : (
          <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
        )}
      </div>
    </GoogleOAuthProvider>
  );
};

export default WebViewRedirect;
