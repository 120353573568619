import {
  authenticate,googleSign,
  resetpassword,
  verifypassword,
  categories,
  banner,
  products,
  categoryproducts,
  singleproducts,
  addwishlist,
  getwishlist,
  addcart,
  getcart,
  getcartcount,
  updatecart,
  removecart,
  buynow,
  checkout,
  searchproduct,
  hotdeal,
  foryou,
  getorder,
  getorderdetail,
  faq,
  register,
  logout,
  getprofile,
  addAddres,
  // UpdateAddres,
  getAddres,
  getReview,
  addReview,
  updateprofile,
  productspec,
  myreview,
  deletereview,
  deleteAddres,
  fProducts,
  filterproduct,
  welcomeMessage,
} from "../ActionType";

//global lodaerState

const initialState = {};

export const globalLoder = (state = initialState, actions) => {
  switch (actions.type) {
    case "showLoader":
      return {
        payload: true,
      };
    case "hideLoader":
      return {
        payload: false,
      };
    default:
      return state;
  }
};

//welcomeMessages
export const getWelcomeMessages = (state = initialState, actions) => {
  switch (actions.type) {
    case welcomeMessage.welcome.success:
      return {
        status: true,
        payload: actions.data,
      };
    case welcomeMessage.welcome.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//User
export const User = (state = initialState, actions) => {
  switch (actions.type) {
    case authenticate.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case authenticate.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//google login
export const googleLogin = (state = initialState, actions) => {
  switch (actions.type) {
    case googleSign.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case googleSign.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//Reset Password
export const resetpasswords = (state = initialState, actions) => {
  switch (actions.type) {
    case resetpassword.reset.success:
      return {
        status: true,
        payload: actions.data,
      };
    case resetpassword.reset.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//Verify Password
export const verifypasswords = (state = initialState, actions) => {
  switch (actions.type) {
    case verifypassword.verify.success:
      return {
        status: true,
        payload: actions.data,
      };
    case verifypassword.verify.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//Category
export const getcategories = (state = initialState, actions) => {
  switch (actions.type) {
    case categories.getcategories.success:
      return {
        status: true,
        payload: actions.data,
      };
    case categories.getcategories.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//Banner start
export const getbanner = (state = initialState, actions) => {
  switch (actions.type) {
    case banner.getbanner.success:
      return {
        status: true,
        payload: actions.data,
      };
    case banner.getbanner.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//Product
export const getproducts = (state = initialState, actions) => {
  switch (actions.type) {
    case products.getproducts.success:
      return {
        status: true,
        payload: actions.data,
      };
    case products.getproducts.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const getcategoryproducts = (state = initialState, actions) => {
  switch (actions.type) {
    case categoryproducts.getproducts.success:
      return {
        status: true,
        payload: actions.data,
      };
    case categoryproducts.getproducts.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

export const getsingleproducts = (state = initialState, actions) => {
  switch (actions.type) {
    case singleproducts.getproducts.success:
      return {
        status: true,
        payload: actions.data,
      };
    case singleproducts.getproducts.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//hotdeals
export const hotdeals = (state = initialState, actions) => {
  switch (actions.type) {
    case hotdeal.hot.success:
      return {
        status: true,
        payload: actions.data,
      };
    case hotdeal.hot.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//foryou
export const foryous = (state = initialState, actions) => {
  switch (actions.type) {
    case foryou.you.success:
      return {
        status: true,
        payload: actions.data,
      };
    case foryou.you.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
// Wishlist
export const getwishlists = (state = initialState, actions) => {
  switch (actions.type) {
    case getwishlist.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getwishlist.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

export const addwishlists = (state = initialState, actions) => {
  switch (actions.type) {
    case addwishlist.add.success:
      return {
        status: true,
        payload: actions.data,
      };
    case addwishlist.add.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// Cart
export const addcarts = (state = initialState, actions) => {
  switch (actions.type) {
    case addcart.add.success:
      return {
        status: true,
        payload: actions.data,
      };
    case addcart.add.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

export const getcarts = (state = initialState, actions) => {
  switch (actions.type) {
    case getcart.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getcart.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

export const getcartcounts = (state = initialState, actions) => {
  switch (actions.type) {
    case getcartcount.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getcartcount.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

export const updatecarts = (state = initialState, actions) => {
  switch (actions.type) {
    case updatecart.update.success:
      return {
        status: true,
        payload: actions.data,
      };
    case updatecart.update.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

export const removecarts = (state = initialState, actions) => {
  switch (actions.type) {
    case removecart.remove.success:
      return {
        status: true,
        payload: actions.data,
      };
    case removecart.remove.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

// buynow

export const buynows = (state = initialState, actions) => {
  switch (actions.type) {
    case buynow.buy.success:
      return {
        status: true,
        payload: state,
      };
    case buynow.buy.error:
      return {
        status: false,
        payload: typeof actions.data === String,
      };
    default:
      return state;
  }
};
//checkout
export const checkouts = (state = initialState, actions) => {
  switch (actions.type) {
    case checkout.check.success:
      return {
        status: true,
        payload: actions.data,
      };
    case checkout.check.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//search
export const searchs = (state = initialState, actions) => {
  switch (actions.type) {
    case searchproduct.search.success:
      return {
        status: true,
        payload: actions.data,
      };
    case searchproduct.search.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
}; //filter
export const filters = (state = initialState, actions) => {
  switch (actions.type) {
    case filterproduct.filter.success:
      return {
        status: true,
        payload: actions.data,
      };
    case filterproduct.filter.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//orders
export const getorders = (state = initialState, actions) => {
  switch (actions.type) {
    case getorder.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getorder.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//ordersdetails
export const getordersdetails = (state = initialState, actions) => {
  switch (actions.type) {
    case getorderdetail.details.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getorderdetail.details.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//faqs
export const faqs = (state = initialState, actions) => {
  switch (actions.type) {
    case faq.quest.success:
      return {
        status: true,
        payload: actions.data,
      };
    case faq.quest.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//Register
export const registers = (state = initialState, actions) => {
  switch (actions.type) {
    case register.sent.success:
      return {
        status: true,
        payload: actions.data,
      };
    case register.sent.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//logout
export const logouts = (state = initialState, actions) => {
  switch (actions.type) {
    case logout.sent.success:
      return {
        status: true,
        payload: actions.data,
      };
    case logout.sent.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//Getprofile
export const getprofiles = (state = initialState, actions) => {
  switch (actions.type) {
    case getprofile.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getprofile.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//Getprofile
export const updateprofiles = (state = initialState, actions) => {
  switch (actions.type) {
    case updateprofile.update.success:
      return {
        status: true,
        payload: actions.data,
      };
    case updateprofile.update.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//addAddres
export const addAddress = (state = initialState, actions) => {
  switch (actions.type) {
    case addAddres.add.success:
      return {
        status: true,
        payload: actions.data,
      };
    case addAddres.add.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//getAddres
export const DeleteAddress = (state = initialState, actions) => {
  switch (actions.type) {
    case deleteAddres.delete.success:
      return {
        status: true,
        payload: actions.data,
      };
    case deleteAddres.delete.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//delAddres
export const getAddress = (state = initialState, actions) => {
  switch (actions.type) {
    case getAddres.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getAddres.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//getReviews
export const getreviews = (state = initialState, actions) => {
  switch (actions.type) {
    case getReview.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case getReview.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//addReviews
export const addreviews = (state = initialState, actions) => {
  switch (actions.type) {
    case addReview.add.success:
      return {
        status: true,
        payload: actions.data,
      };
    case addReview.add.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};

//productspec
export const productspecifications = (state = initialState, actions) => {
  switch (actions.type) {
    case productspec.spec.success:
      return {
        status: true,
        payload: actions.data,
      };
    case productspec.spec.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//myreviews
export const myreviews = (state = initialState, actions) => {
  switch (actions.type) {
    case myreview.review.success:
      return {
        status: true,
        payload: actions.data,
      };
    case myreview.review.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//deletereviews
export const deletereviews = (state = initialState, actions) => {
  switch (actions.type) {
    case deletereview.review.success:
      return {
        status: true,
        payload: actions.data,
      };
    case deletereview.review.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
//Filter Products

export const FilterProducts = (state = initialState, actions) => {
  switch (actions.type) {
    case fProducts.fProduct.success:
      return {
        status: true,
        payload: actions.data,
      };
    case fProducts.fProduct.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
