import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // useLocation to access state
import { Registers, globalLoderAction } from "../Redux/Action";
import { useDispatch } from "react-redux";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { message } from "antd";

function Register() {
  const [user, setUser] = useState({
    email: "",
    password: "",
    phone: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [load, setLoad] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // to get the passed state

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (!/^[0-9]{10}$/.test(value)) {
        setPhoneError("Please enter a valid 10-digit phone number");
      } else {
        setPhoneError("");
      }
    }

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(Registers(user)).then((res) => {
      setLoad(true);
      if (res.data.data.token) {
        message.success({
          content: "Register Successfully",
          style: { marginTop: "10vh" },
        });
        const redirectTo = location.state?.from || "/"; // Default to home if no 'from' state
        navigate(redirectTo, { state: { fromLogin: true } }); // Include fromLogin state
        window.location.reload(); // Optional, reload if needed
      } else {
        message.error({
          content: res.data.data,
          style: { marginTop: "10vh" },
        });
      }
      setLoad(false);
    });
  };

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <>
      <div className="user-area pt-100 pb-70">
        <div className="container">
          <div
            className="user-all-form"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div className="contact-form">
              <h3 className="user-title">Register</h3>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        required
                        className="form-control"
                        placeholder="Enter Your Email ID"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        value={user.phone}
                        onChange={handleChange}
                        className={`form-control ${
                          phoneError ? "is-invalid" : ""
                        }`}
                        maxLength={10}
                        placeholder="Enter Your Phone Number"
                        required
                      />
                    </div>
                    {phoneError && (
                      <div
                        className="invalid-feedback"
                        style={{ color: "red" }}
                      >
                        {phoneError}
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="form-group" style={{ display: "flex" }}>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        required
                        name="password"
                        value={user.password}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Set Your Password"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{
                          borderRadius: "none",
                          background: "none",
                          marginLeft: "-50px",
                          border: "none",
                          fontSize: "20px",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? (
                          <BsEyeSlash style={{ color: "#6b9246" }} />
                        ) : (
                          <BsEye style={{ color: "#6b9246" }} />
                        )}
                      </button>
                    </div>
                  </div>
                  <div
                    className="col-lg-12 col-md-12"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      type="submit"
                      className="default-btn border-radius-5"
                    >
                      Register Now
                    </button>
                    <div className="mt-2">
                      Already have an Account?{" "}
                      <a
                        className="login"
                        href="/login"
                        style={{ color: "#6b9246" }}
                      >
                        Sign in
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
