import React, { useState, useEffect } from "react";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { FaStar } from "react-icons/fa";
import { addreviews } from "../Redux/Action";
import { useParams } from "react-router-dom";

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};

function ReviewContainer({ order, index, onClose }) {
  const { id } = useParams();
  const [currentValue, setCurrentValue] = useState(0);
  const [review, setReview] = useState("");
  const dispatch = useDispatch();
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);
  console.log(index);

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const AdddReview = () => {
    dispatch(
      addreviews({
        productId: order.productId,
        variantId: order.variantId,
        orderId: id,
        rating: currentValue,
        review: review,
        reviewIndex: index,
      })
    )
      .then((res) => {
        message.success({
          content: res?.data?.data || "Review added successfully",
          style: {
            marginTop: "10vh",
          },
        });
        onClose();
        // Close the modal after successful submission
      })
      .catch((error) => {
        console.error(error);
        message.error({
          content: "Failed to add review",
          style: {
            marginTop: "10vh",
          },
        });
      });
  };

  return (
    <div style={styles.container}>
      <h2> Ratings </h2>
      <div style={styles.stars}>
        {stars.map((_, index) => (
          <FaStar
            key={index}
            size={20}
            onClick={() => handleClick(index + 1)}
            onMouseOver={() => handleMouseOver(index + 1)}
            onMouseLeave={handleMouseLeave}
            color={
              (hoverValue || currentValue) > index ? colors.orange : colors.grey
            }
            style={{
              marginRight: 10,
              cursor: "pointer",
            }}
          />
        ))}
      </div>
      <textarea
        placeholder="What's your experience?"
        onChange={(e) => setReview(e.target.value)}
        value={review}
        style={styles.textarea}
      />
      <button style={styles.button} onClick={AdddReview}>
        Submit
      </button>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stars: {
    display: "flex",
    flexDirection: "row",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: 300,
  },
  button: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    width: 300,
    padding: 10,
  },
};

export default ReviewContainer;
