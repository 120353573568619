import React, { useEffect, useState } from "react";
import { myreviews } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { deletereviews, globalLoderAction, getorders } from "../Redux/Action";
import reviews from "../assets/image/reviews found.svg";
import { Link, useNavigate } from "react-router-dom";
import { Modal, message } from "antd";
import ReviewModal from "./ReviewModal";

function ListReviews({ setActiveTab }) {
  const [show, setShow] = useState(false); // Initialize as false
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    dispatch(myreviews())
      .then(() => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  const myreview = useSelector((state) => state.myreviews.payload);

  const handlereview = (G) => {
    dispatch(
      deletereviews({
        action: "delete",
        id: G.id,
        rating: G.rating,
        review: G.review,
      })
    ).then((res) => {
      message.success({
        content: res.data.data,
        style: { marginTop: "10vh" },
      });
      setActiveTab(4);
    });
  };
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getorders())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  const order = useSelector((state) => state.getorders.payload);
  const refreshOrderDetails = () => {
    setLoad(true);
    dispatch(myreviews()).finally(() => setLoad(false));
  };

  const [selectedProduct, setSelectedProduct] = useState(null); // Track the selected product

  const handleEditClick = (product) => {
    setSelectedProduct(product); // Set the selected product
    setShow(true); // Open the modal
  };
  const navigate = useNavigate();
  const handleReviewClick = (event) => {
    event.preventDefault(); // Prevent default Link behavior
    if (Array.isArray(order) && order.length === 0) {
      // Redirect to the shop page if order is an empty array
      navigate("/shop");
    } else if (Array.isArray(order) && order.length > 0) {
      // Redirect to the orders page if there is data in order
      navigate("/order");
    }
  };
  return (
    <div>
      <div className="view-more mt-5">
        <Link
          className="default-btn border-radius-5"
          onClick={handleReviewClick}
        >
          Add Your Review
        </Link>
      </div>
      <section className="cart-wraps-area pt-10 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cart-wraps">
                <div className="cart-table table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      {Array.isArray(myreview) && myreview.length > 0 ? (
                        <tr>
                          <th scope="col">Product Image</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Review</th>
                          <th scope="col">Rating</th>
                          <th scope="col">Action</th>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={5} style={{ textAlign: "center" }}>
                            <h3 className="pt-5 pb-5">
                              <img
                                src={reviews}
                                className="mt-5 mb-5 pb-5 pt-5"
                                style={{ width: "25%", objectFit: "cover" }}
                              />
                              <h3>No Reviews Found</h3>
                            </h3>
                          </td>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {Array.isArray(myreview) && myreview.length > 0
                        ? myreview.map((G, i) => (
                            <tr key={i}>
                              <td className="product-thumbnail proo">
                                <Link
                                  to={`/ProductDetails/${G.productId}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <img
                                    style={{ width: "100px" }}
                                    src={`${process.env.REACT_APP_URL}${G.variantImage}`}
                                  />
                                </Link>
                              </td>
                              <td className="product-name">
                                <Link
                                  to={`/ProductDetails/${G.productId}`}
                                  style={{
                                    textTransform: "capitalize",
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  {G.productName}
                                </Link>
                              </td>
                              <td className="product-price">
                                <Link
                                  to={`/ProductDetails/${G.productId}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  <span style={{ textTransform: "capitalize" }}>
                                    {G.review}
                                  </span>
                                </Link>
                              </td>
                              <td className="product-name">{G.rating}</td>
                              <td className="product-subtotal">
                                <span onClick={() => handleEditClick(G)}>
                                  <i
                                    style={{
                                      fontSize: "23px",
                                      backgroundColor: "#0000001c",
                                      borderRadius: "0",
                                      padding: "10px",
                                    }}
                                    className="bx bx-edit-alt"
                                  ></i>
                                </span>
                                <span onClick={() => handlereview(G)}>
                                  <a
                                    style={{
                                      cursor: "pointer",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <i
                                      style={{
                                        fontSize: "23px",
                                        backgroundColor: "#0000001c",
                                        borderRadius: "0",
                                        padding: "10px",
                                      }}
                                      className="bx bx-trash"
                                    ></i>
                                  </a>
                                </span>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title="Product Review"
        visible={show}
        onCancel={() => setShow(false)} // Close the modal
        footer={null}
      >
        {selectedProduct && (
          <ReviewModal
            myreview={selectedProduct}
            onClose={() => {
              setShow(false); // Close the modal
              refreshOrderDetails(); // Reload the data
            }}
          />
        )}
      </Modal>
    </div>
  );
}

export default ListReviews;
