// import { message } from "antd";
import { axiosInstance } from "../Axios/Services";
import {
  authenticate,
  resetpassword,
  verifypassword,
  categories,
  banner,
  products,
  categoryproducts,
  singleproducts,
  googleSign,
  addwishlist,
  getwishlist,
  addcart,
  getcart,
  getcartcount,
  updatecart,
  removecart,
  buynow,
  checkout,
  searchproduct,
  hotdeal,
  filterproduct,
  foryou,
  getorder,
  getorderdetail,
  faq,
  register,
  logout,
  getprofile,
  addAddres,
  // UpdateAddres,
  getAddres,
  getReview,
  addReview,
  // delReview,
  productspec,
  updateprofile,
  myreview,
  deletereview,
  deleteAddres,
  fProducts,
  welcomeMessage,
  payNow,
} from "../Redux/ActionType";

// welcome modal and text
export function getWelcomeMessages() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/shop/welcomeMessage")
        .then((res) => {
          dispatch({
            type: welcomeMessage.welcome.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: welcomeMessage.welcome.error,
            data: err,
          });
          reject(err);
        })
    );
}

export const globalLoderAction = (status) => {
  return (dispatch) =>
    dispatch({
      type: status,
    });
};

const Token = localStorage.getItem("AuthTok");
export function authenticatelogin(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "user/auth/email", data)
        .then((res) => {
          localStorage.setItem("AuthTok", res.data.data.token);
          dispatch({
            type: authenticate.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: authenticate.login.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function googlelogin(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "user/auth/googleSignin", data)
        .then((res) => {
          console.log(res);

          localStorage.setItem("AuthTok", res.data.data);
          dispatch({
            type: googleSign.login.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: googleSign.login.error,
            data: err.message,
          });
          reject(err);
        })
    );
}
export function resetpasswords(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "user/auth/sendEmailCode", data)
        .then((res) => {
          dispatch({
            type: resetpassword.reset.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: resetpassword.reset.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function verifypasswords(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "user/auth/verifyEmailCode", data)
        .then((res) => {
          localStorage.setItem("AuthTok", res.data.data.token);
          dispatch({
            type: verifypassword.verify.success,
            data: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: verifypassword.verify.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function getcategories(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "user/shop/category")
        .then((res) => {
          if (Array.isArray(res?.data?.data)) {
            dispatch({
              type: categories.getcategories.success,
              data: res?.data?.data,
            });
            resolve(res);
          }
        })
        .catch((err) => {
          const errorType = err?.response?.data?.error?.type;
          if (
            err?.response?.status === 400 &&
            errorType === "UnauthorizedException"
          ) {
            localStorage.removeItem("AuthTok");
            window.location.href = "/login"; // Redirect to login page
          }
          dispatch({
            type: categories.getcategories.error,
            data: err,
          });
          reject(err);
        })
    );
}

export function getbanner(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "user/shop/banners")
        .then((res) => {
          if (Array.isArray(res?.data?.data)) {
            dispatch({
              type: banner.getbanner.success,
              data: res?.data?.data,
            });
          }
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: banner.getbanner.error,
            data: err,
          });
          reject(err);
        })
    );
}

export function getproducts(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/product")
        .then((res) => {
          if (Array.isArray(res?.data?.data)) {
            dispatch({
              type: products.getproducts.success,
              data: res?.data?.data,
            });
            resolve(res);
          }
        })
        .catch((err) => {
          const errorType = err?.response?.data?.error?.type;
          if (
            err?.response?.status === 400 &&
            errorType === "UnauthorizedException"
          ) {
            localStorage.removeItem("AuthTok");
            window.location.href = "/login"; // Redirect to login page
          }
          dispatch({
            type: products.getproducts.error,
            data: err,
          });
          reject(err);
        })
    );
}

export function getcategoryproducts(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/shop/categoryProducts", data)
        .then((res) => {
          if (Array.isArray(res?.data?.data)) {
            dispatch({
              type: categoryproducts.getproducts.success,
              data: res?.data?.data,
            });
          } else {
            dispatch({
              type: categoryproducts.getproducts.success,
              data: res?.data?.data,
            });
          }
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: categoryproducts.getproducts.error,
            data: err,
          });
          reject(err);
        })
    );
}

export function getsingleproducts(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/product/variant", data)
        .then((res) => {
          if (Array.isArray(res?.data?.data)) {
            dispatch({
              type: singleproducts.getproducts.success,
              data: res?.data?.data,
            });
            resolve(res);
          }
        })
        .catch((err) => {
          dispatch({
            type: singleproducts.getproducts.error,
            data: err,
          });
          reject(err);
        })
    );
}
//hotdeals

export function hotdeals(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/shop/hotDeals")
        .then((res) => {
          if (Array.isArray(res?.data?.data)) {
            dispatch({
              type: hotdeal.hot.success,
              data: res?.data?.data,
            });
          }
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: hotdeal.hot.error,
            data: err,
          });
          reject(err);
        })
    );
}
//foryou
export function foryous(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/shop/forYou")
        .then((res) => {
          if (Array.isArray(res?.data?.data)) {
            dispatch({
              type: foryou.you.success,
              data: res?.data?.data,
            });
            resolve(res);
          }
        })
        .catch((err) => {
          dispatch({
            type: foryou.you.error,
            data: err,
          });
          reject(err);
        })
    );
}

//wishlist
export function getwishlists(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/customer/getWishlist")
        .then((res) => {
          dispatch({
            type: getwishlist.get.success,
            data: res?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: getwishlist.get.error,
            data: err.response.data.error.message,
          });
          reject(err);
        })
    );
}
export function addwishlists(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/customer/wishlist/", data)
        .then((res) => {
          if (Array.isArray(res?.data?.data)) {
            dispatch({
              type: addwishlist.add.success,
              data: res?.data?.data,
            });
          }
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: addwishlist.add.error,
            data: err,
          });
          reject(err);
        })
    );
}

// Cart

export function addcarts(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/cart/addCart", data)
        .then((res) => {
          dispatch(getcarts());
          dispatch(getcartcounts());
          dispatch({
            type: addcart.add.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: addcart.add.error,
            data: err.response.data.error.message,
          });
          reject(err);
        })
    );
}

export function getcarts(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/cart")
        .then((res) => {
          dispatch(getcartcounts());
          dispatch({
            type: getcart.get.success,
            data: res?.data?.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: getcart.get.error,
            data: err.response.data.error.message,
          });
          reject(err);
        })
    );
}

export function getcartcounts(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/cart/count")
        .then((res) => {
          dispatch({
            type: getcartcount.get.success,
            data: res?.data?.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: getcartcount.get.error,
            data: err.response.data.error.message,
          });
          reject(err);
        })
    );
}

export function updatecarts(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/cart/updateCart", data)
        .then((res) => {
          dispatch(getcarts());
          dispatch(getcartcounts());
          dispatch({
            type: updatecart.update.success,
            data: res?.data?.data,
          });

          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: updatecart.update.error,
            data: err,
          });
          reject(err);
        })
    );
}
// RemoveCarts
export function removecarts(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/cart/removeCart", data)
        .then((res) => {
          dispatch(getcarts());
          dispatch(getcartcounts());
          dispatch({
            type: removecart.remove.success,
            data: res?.data?.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: removecart.remove.error,
            data: err,
          });
          reject(err);
        })
    );
}
// Buynow
export function buynows(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      Token
        ? axiosInstance("post", "/user/cart/buy", data)
            .then((res) => {
              // dispatch({
              //   type: buynow.buy.success,
              //   data: res?.data?.data,
              // });
              resolve(res);
            })
            .catch((err) => {
              dispatch({
                type: buynow.buy.error,
                data: err,
              });
              reject(err);
            })
        : (window.location.href = "/login")
    );
}
// checkout
export function checkouts(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/orders/checkout", data)
        .then((res) => {
          dispatch({
            type: checkout.check.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: checkout.check.error,
            data: err,
          });
          reject(err);
        })
    );
}

export function payNows(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/orders/payment", data)
        .then((res) => {
          dispatch(getcartcounts());
          dispatch({
            type: payNow.pay.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: payNow.pay.error,
            data: err,
          });
          reject(err);
        })
    );
}

// search
export function searchs(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/search/", { query: data })
        .then((res) => {
          dispatch({
            type: searchproduct.search.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: searchproduct.search.error,
            data: err,
          });
          reject(err);
        })
    );
}
// filter
export function filters(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/search/filters", { priceRange: data })
        .then((res) => {
          dispatch({
            type: filterproduct.filter.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: filterproduct.filter.error,
            data: err,
          });
          reject(err);
        })
    );
}
//orders
export function getorders(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/orders")
        .then((res) => {
          dispatch({
            type: getorder.get.success,
            data: res?.data?.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: getorder.get.error,
            data: err,
          });
          reject(err);
        })
    );
}
//ordersdetails
export function getordersdetails(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/orders/getOrder", data)
        .then((res) => {
          dispatch({
            type: getorderdetail.details.success,
            data: res?.data?.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: getorderdetail.details.error,
            data: err,
          });
          reject(err);
        })
    );
}
//faq
export function faqs(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/shop/faq")
        .then((res) => {
          dispatch({
            type: faq.quest.success,
            data: res?.data?.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: faq.quest.error,
            data: err,
          });
          reject(err);
        })
    );
}
//Registers
export function Registers(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/customer/register", data)
        .then((res) => {
          localStorage.setItem("AuthTok", res.data.data.token);

          dispatch({
            type: register.sent.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: register.sent.error,
            data: err,
          });
          reject(err);
        })
    );
}
// logout
export function logouts() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/auth/logout")
        .then((res) => {
          dispatch({
            type: logout.sent.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: logout.sent.error,
            data: err,
          });
          reject(err);
        })
    );
}
// Getuser
export function getprofiles() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/customer/getUser")
        .then((res) => {
          dispatch({
            type: getprofile.get.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: getprofile.get.error,
            data: err,
          });
          reject(err);
        })
    );
}
// Updateprofile
export function updateprofiles(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/customer/updateUser", data)
        .then((res) => {
          dispatch({
            type: updateprofile.update.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: updateprofile.update.error,
            data: err,
          });
          reject(err);
        })
    );
}
// Addaddress
export function addAddress(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/customer/addAddress", data)
        .then((res) => {
          dispatch({
            type: addAddres.add.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: addAddres.add.error,
            data: err,
          });
          reject(err);
        })
    );
}

// Updateaddress
export function DeleteAddress(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/customer/updateAddress", data)
        .then((res) => {
          dispatch({
            type: deleteAddres.delete.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: deleteAddres.delete.error,
            data: err,
          });
          reject(err);
        })
    );
}
// getaddress
export function getAddress(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/customer/getAddress")
        .then((res) => {
          dispatch({
            type: getAddres.get.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: getAddres.get.error,
            data: err,
          });
          reject(err);
        })
    );
}
// getreviews
export function getreviews(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "user/review", data)
        .then((res) => {
          dispatch({
            type: getReview.get.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: getReview.get.error,
            data: err,
          });
          reject(err);
        })
    );
}
// addreviews
export function addreviews(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/review/addReview", data)
        .then((res) => {
          dispatch({
            type: addReview.add.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: addReview.add.error,
            data: err,
          });
          reject(err);
        })
    );
}

// productspec
export function productspecifications(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/product/specification", data)
        .then((res) => {
          dispatch({
            type: productspec.spec.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: productspec.spec.error,
            data: err,
          });
          reject(err);
        })
    );
}
// My Reviews
export function myreviews() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/user/review/MyReview")
        .then((res) => {
          dispatch({
            type: myreview.review.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: myreview.review.error,
            data: err,
          });
          // reject(err);
        })
    );
}
// Delete Reviews
export function deletereviews(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/review/removeReview", data)
        .then((res) => {
          dispatch({
            type: deletereview.review.success,
            data: res?.data?.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: deletereview.review.error,
            data: err,
          });
          reject(err);
        })
    );
}
// filter
export function FilterProducts(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/user/product", data)
        .then((res) => {
          if (Array.isArray(res?.data?.data)) {
            dispatch({
              type: fProducts.fProduct.success,
              data: res?.data?.data,
            });
            resolve(res);
          } else {
            dispatch({
              type: fProducts.fProduct.success,
              data: res?.data?.data,
            });
            resolve(res);
          }
        })
        .catch((err) => {
          dispatch({
            type: fProducts.fProduct.error,
            data: err,
          });
          reject(err);
        })
    );
}
